<!--
  此首页对内展示
  服务用户主页对外展示
 -->
<template>
  <div class="index tw-flex">
    <div class="index_left tw-flex-1">
      <div class="panel org">
        <div class="org_main tw-flex tw-justify-between">
          <div class="org_main_left">
            <img v-if="organization.logoUrl" :src="organization.logoUrl" style="object-fit: cover;" alt="">
            <img v-else src="../../assets/images/default_avatar.png" alt="">
            <div class="org_info">
              <div class="org_info_name">
                <p class="tw-text-20px tw-font-bold">{{organization.name}}</p>
              </div>
              <!-- <p class="org_info_area tw-font-bold">{{userInfo.contactAddress}}</p> -->
            </div>
          </div>
          <div class="org_main_right">
            <div class="credit_box">
              <p class="credit_box_label">信用评价</p>
              <div class="credit_box_value">
                <!-- 以前用的是此字段 creditScore -->
                <el-rate
                  :value="organization.avgScore / 2"
                  disabled
                  :colors="['#FF9900','#FF9900','#FF9900']"
                  text-color="">
                </el-rate>
                <span class="tw-text-14px tw-font-bold">
                  {{organization.avgScore}}
                </span>
              </div>
            </div>
            <div class="synthesize_box">
              <p class="synthesize_box_label">综合评价</p>
              <div class="synthesize_box_value">
                <p>服务质量<span>{{ organization.serviceQuality }}</span></p>
                <p>服务效果<span>{{ organization.serviceEffect }}</span></p>
                <p>服务态度<span>{{ organization.serviceAttitude }}</span></p>
              </div>
            </div>
          </div>
        </div>
        <div class="org_footer tw-flex">
          <!-- 机构总交易额 -->
          <div class="org_footer_item">
            <img src="../../assets/images/index/index_icon_1.png" alt="">
            <div class="tw-flex tw-flex-col tw-justify-between">
              <p class="org_footer_item-title tw-text-14px tw-font-bold">机构交易额</p>
              <p class="org_footer_item-number tw-text-20px tw-font-bold">{{organization.totalTurnover}}元</p>
            </div>
          </div>
          <!-- 待处理订单数 -->
          <div class="org_footer_item">
            <img src="../../assets/images/index/index_icon_2.png" alt="">
            <div class="tw-flex tw-flex-col tw-justify-between">
              <p class="org_footer_item-title tw-text-14px tw-font-bold">待处理订单</p>
              <p class="org_footer_item-number tw-text-20px tw-font-bold">{{organization.pendingOrders}}</p>
            </div>
          </div>
          <!-- 提供服务数量 -->
          <div class="org_footer_item">
            <img src="../../assets/images/index/index_icon_3.png" alt="">
            <div class="tw-flex tw-flex-col tw-justify-between">
              <p class="org_footer_item-title tw-text-14px tw-font-bold">机构服务产品数量</p>
              <p class="org_footer_item-number tw-text-20px tw-font-bold">{{organization.providedServicesNumber}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="panel require">
        <div class="panel_title">
          <p class="tw-text-16px tw-font-bold">需求推荐</p>
        </div>
        <div class="require_list">
          <require-recommend :list="requireList" />
        </div>
      </div>
    </div>
    <div class="index_right">
      <div class="panel info">
        <div class="panel_title">
          <p class="tw-text-16px tw-font-bold">资讯</p>
        </div>
        <div class="info_main">
          <el-tabs v-model="activeTab">
            <el-tab-pane v-for="item in upInfo" :key="item.id" :label="item.name" :name="item.id">
              <div class="info_item" v-for="infoItem in item.list" :key="infoItem.id" @click="checkInfo(infoItem)">
                <p class="info_item_title tw-text-14px">{{infoItem.title}}</p>
                <p class="info_item_time tw-text-12px">{{infoItem.updatedAt}}</p>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="panel info">
        <div class="panel_title">
          <p class="tw-text-16px tw-font-bold">{{downInfo.name}}</p>
        </div>
        <div class="info_main">
          <div class="info_item" v-for="item in downInfo.list" :key="item.id" @click="checkInfo(item)">
            <p class="info_item_title tw-text-14px">{{item.title}}</p>
            <p class="info_item_time tw-text-12px">{{item.updatedAt}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

import requireRecommend from './require-recommend'

export default {
  components: {
    requireRecommend
  },
  data () {
    return {
      activeTab: '',
      requireList: [],
      upInfo: [], // 资讯
      downInfo: {} // 线下活动
    }
  },
  created () {
    this.getRequireList()
    this.getInfoList()
  },
  methods: {
    // 随机推荐
    getRequireList (pageSize = 10) {
      api.getRecommendRequireList({
        pageSize
      }).then(res => {
        if (res.data.code === 0) {
          this.requireList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    getInfoList (pageSize = 5) {
      api.getIndexInfoList({
        pageSize
      }).then(res => {
        if (res.data.code === 0) {
          this.upInfo = res.data.data.up
          this.downInfo = res.data.data.down
          this.activeTab = this.upInfo[0].id
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    checkInfo (item) {
      const { href } = this.$router.resolve({
        path: `/info-detail/${item.id}`
      })
      window.open(href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.index {
  min-width: 1350px;
  &_left {
    margin-right: 20px;
  }
  &_right {
    width: 450px;
  }
}
.panel {
  padding: 0 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  &_title {
    padding: 20px 3px;
    & > p {
      position: relative;
      color: #000000;
      line-height: 22px;
      &::before {
        content: "";
        position: absolute;
        left: -13px;
        top: 50%;
        transform: translateY(-50%);
        width: 3px;
        height: 20px;
        background-color: #3473E6;
        border-radius: 1px;
      }
    }
  }
}

.org {
  &_main {
    height: 130px;
    padding: 20px 30px 30px 10px;
    &_left {
      display: flex;
      & > img {
        width: 80px;
        height: 80px;
        margin-right: 30px;
        border-radius: 4px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      }
      .org_info {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &_name {
          display: flex;
          align-items: center;
          & > p {
            color: #000000;
            margin-right: 16px;
          }
        }
        &_icon {
          color: #3473E6;
        }
        &_area {
          color: #909399;
          line-height: 20px;
        }
      }
    }
    &_right {
      display: flex;
      .credit_box {
        display: flex;
        width: 250px;
        height: 30px;
        align-items: center;
        .credit_box_label {
          width: 82px;
          font-size: 14px;
          font-weight: bold;
          line-height: 30px;
          color: #606266;
        }
        .credit_box_value {
          display: flex;
          width: 150px;
          & > span {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 20px;
            color: #FF9900;
          }
        }
      }
      .synthesize_box {
        display: flex;
        width: 250px;
        padding: 0 30px;
        .synthesize_box_label {
           width: 82px;
          font-size: 14px;
          font-weight: bold;
          line-height: 30px;
          color: #606266;
        }
        .synthesize_box_value {
          & > p {
            font-size: 12px;
            line-height: 30px;
            color: #606266;
            & > span {
              font-size: 12px;
              font-weight: bold;
              line-height: 17px;
              color: #3473E6;
              margin-left: 22px;
            }
          }
        }
      }
    }
  }
  &_footer {
    height: 90px;
    border-top: 1px solid #DCDFE6;
    &_item {
      flex: 1;
      padding: 20px 10px 16px 10px;
      display: flex;
      align-items: center;
      & > img {
        margin-right: 30px;
      }
      & > div {
        height: 100%;
      }
      &-title {
        line-height: 20px;
        color: #606266;
      }
      &-number {
        line-height: 28px;
        color: #3473E6;
      }
    }
  }
}

.require {
  margin-top: 20px;
  padding-bottom: 20px;
}

.info {
  &+ & {
    margin-top: 20px;
  }
  &_main {
    padding-bottom: 50px;
  }
  &_item {
    height: 80px;
    padding: 15px 0;
    border-bottom: 1px dashed #DCDFE6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &_title {
      color: #303133;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        cursor: pointer;
        color: #3473E6;
        font-weight: bold;
      }
    }
    &_time {
      color: #909399;
    }
  }
}
</style>
