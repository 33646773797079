<template>
  <div class="result">
    <div class="result_header tw-flex tw-items-center">
      <div class="result_header_item tw-flex tw-items-center tw-justify-center" style="flex: 320px;">发布用户</div>
      <div class="result_header_item tw-flex tw-items-center tw-justify-center" style="flex: 320px;">需求名称</div>
      <div class="result_header_item tw-flex tw-items-center tw-justify-center" style="flex: 490px;">需求描述</div>
    </div>
    <div class="result_body">
      <div class="result_body_item item" v-for="item in list" :key="item.id" @click="clickItem(item)">
        <div class="item_col col_1" style="flex: 320px;">
          <img v-if="item.avatarUrl" :src="item.avatarUrl" style="object-fit: cover;" alt="">
          <img v-else src="../../assets/images/default_avatar.png" alt="">
          <p class="tw-text-14px tw-font-bold">{{item.username}}</p>
        </div>
        <div class="item_col col_2" style="flex: 320px;">
          <p class="tw-text-14px">{{item.title}}</p>
        </div>
        <div class="item_col col_3" style="flex: 490px;">
          <p class="tw-text-14px  text_elip elip_multiple text_elip_line_2 ">{{item.description}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    clickItem (item) {
      const { href } = this.$router.resolve({
        path: `/require-detail/${item.id}`
      })
      this.$emit('click', item)

      window.open(href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.result {
  padding: 10px 0;
  &_header {
    width: 100%;
    height: 48px;
    background: #F7F8F9;
    border-radius: 4px;
    &_item {
      height: 100%;
      padding: 0 20px;
    }
  }
  &_body {
    margin-top: 10px;
    &_item {
      cursor: pointer;
      position: relative;
      width: 100%;
      height: 60px;
      background: #FFFFFF;
      border-radius: 4px;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
      }
      &:hover {
        &::after {
          border-color: #3473E6;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        }
      }
    }
  }
}

.item {
  margin-bottom: 10px;
  display: flex;
  &_col {
    height: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .col_1 {
    justify-content: flex-start;
    & > img {
      width: 40px;
      height: 40px;
      margin: 0 20px;
    }
    & > p {
      color: #303133;
    }
  }
  .col_2 {
    color: #606266;
  }
  .col_3 {
    line-height: 20px;
    color: #606266;
  }
}
</style>
